<div class="container">
    <div [id]=tableId class="tabulator-container"></div>
    <div class="tabulator-bottom-container">
        <div class="column-maker">
            <div class="clear-search-buttons">
                <button [disabled]="!filterIsActive" (click)="clearFilters()" class="header-button">Clear Filters</button>
                <button [disabled]="!filterIsActive" (click)="searchAll()" class="header-button">Search All</button>
              </div>
            <div *ngIf="allowEdit" class="row-operations">
                <img class="add-row-icon" src="../../assets/images/icons/Filladd.svg"
                    [matTooltip]="strings['addNewRecord']" mat-stroked-button matTooltipPosition="above"
                    (click)="addRow()">
                <img class="copy-row-icon" src="../../assets/images/icons/Fillcopy.svg"
                    [matTooltip]="strings['cloneSelectedRecord']" mat-stroked-button matTooltipPosition="above"
                    (click)="duplicateSelectedRows()">
                <img class="delete-row-icon" src="../../assets/images/icons/FilllargeX.svg"
                    [matTooltip]="strings['deleteSelectedRecord']" mat-stroked-button matTooltipPosition="above"
                    (click)="deleteSelectedRows()">
                <img class="undelete-row-icon" src="../../assets/images/icons/Fillundo.svg"
                    [matTooltip]="strings['undeleteSelectedRecord']" mat-stroked-button matTooltipPosition="above"
                    (click)="undeleteSelectedRows()">
                <img src="../../assets/images/icons/Fillsave.svg" class="material-icons-outlined save-table-icon"
                    [matTooltip]="strings['saveChanges']" mat-stroked-button matTooltipPosition="above"
                    (click)="saveTableChanges()">
            </div>
        </div>

        <div class="middle-container">
            <div class="counter-container" *ngIf="rowStateCounts | async as counts">
                <section class="counter-section" matTooltip="Description for this section"></section>
                <section class="counter-section" matTooltip="Number of edited rows">{{ counts.edited }}</section>
                <section class="counter-section" matTooltip="Number of new rows">{{ counts.new }}</section>
                <section class="counter-section" matTooltip="Number of deleted rows">{{ counts.deleted }}</section>
                <section class="counter-section" matTooltip="Total number of Rows Selected">{{ $rowChangesLength | async
                    }}</section>
            </div>
            <div class="row-filters" *ngIf="allowEdit">
                <button matTooltip="{{strings[showOriginalToolTipKey]}}" mat-stroked-button matTooltipPosition="above"
                    [ngClass]="{'original enabled': showOriginal, 'disabled': !showOriginal}"
                    (click)="toggleOriginalRecordsFilter()">
                    <img src="../../assets/images/icons/Fillfilter.svg">
                </button>
                <button matTooltip="{{strings[showChangedToolTipKey]}}" mat-stroked-button matTooltipPosition="above"
                    [ngClass]="{'changed enabled': showChanged, 'disabled': !showChanged}"
                    (click)="toggleEditedOriginalRecordsFilter()">
                    <img src="../../assets/images/icons/Fillfilter.svg">
                </button>
                <button matTooltip="{{strings[showNewToolTipKey]}}" mat-stroked-button matTooltipPosition="above"
                    [ngClass]="{'new enabled': showNew, 'disabled': !showNew}" (click)="toggleNewRecordsFilter()">
                    <img *ngIf="addRowShow" src="../../assets/images/icons/Fillfilter.svg">
                </button>
                <button matTooltip="{{strings[showDeletedToolTipKey]}}" mat-stroked-button matTooltipPosition="above"
                    [ngClass]="{'deleted enabled': showDeleted, 'disabled': !showDeleted}"
                    (click)="toggleDeletedRecordsFilter()">
                    <img src="../../assets/images/icons/Fillfilter.svg">
                </button>
                <button matTooltip="Total Selected Records" mat-stroked-button matTooltipPosition="above"
                    [ngClass]="{'new-deleted enabled': showSelectedRecords, 'disabled': !showSelectedRecords}"
                    (click)="toggleSelectedRecordsFilter()">
                    <img src="../../assets/images/icons/Fillfilter.svg">
                </button>
            </div>

        </div>

        <div class="column-maker">
            <div class="page-selection-container">
                <label>Page Size:</label>
                <select [(ngModel)]="pageSize" (change)="updatePageSize(pageSize)">
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                </select>
                <button [disabled]="currentPage === 1" (click)="loadPage(1)">First</button>
                <button [disabled]="currentPage === 1" (click)="prevPage()">Prev</button>
                <section class="pageRange" *ngFor="let page of getPageRange()">
                    <button [disabled]="currentPage === page" (click)="loadPage(page)">{{page}}</button>
                </section>
                <button [disabled]="currentPage === totalPages" (click)="nextPage()">Next</button>
                <button [disabled]="currentPage === totalPages" (click)="loadPage(totalPages)">Last</button>
            </div>
            <div class="exports">
                <section>{{strings["csv"]}}: </section>
                <img class="csv-download-icon" src="../../assets/images/icons/FileDownload.svg" (click)="downloadCsv()" alt="download as csv file">
              
                <section>{{strings["json"]}}: </section>
                <img class="json-download-icon" src="../../assets/images/icons/FileDownload.svg" (click)="downloadJson()" alt="download as json file">
                <!-- import CSV would be a feature that would allow the user to import a CSV file into the table. -->
              </div>
        </div>
    </div>
</div>