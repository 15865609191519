<div class="side-nav-container">
    <!-- ACTION -->
    <div class="side-nav-sub-container">
        <button (click)="expandSubMenu('action')" (click)="loadActionSolutions()" class="primary-button" [ngClass]="{'button-click':buttonStatus == 'action'}">{{strings["actions"]}}</button>
        <div *ngIf="this.subMenu == 'action'" class="side-nav-click-container">
            <input type="text" (keyup)=" filterActionolutionsList($event.target.value)" placeholder="{{strings['search']}}" />
            <div *ngFor="let solution of filteredActionSolutions | async" class="side-nav-name-div">
                <span (click)="selectActionSolution(solution)" [ngClass]="{'button-click':spanStatus == solution && actionsHighlight == true}">{{solution}}</span>
            </div>
        </div>
    </div>

    <!-- TABLES -->
    <div class="side-nav-sub-container">
        <button (click)="expandSubMenu('tables')" (click)="loadTableNames()" class="primary-button" [ngClass]="{'button-click':buttonStatus == 'tables'}">{{strings["tables"]}}</button>
        <div *ngIf="this.subMenu == 'tables'" class="side-nav-click-container">
            <input type="text" (keyup)="filterTablesSolutionsList($event.target.value)" placeholder="{{strings['search']}}" />
            <div *ngFor="let table of filteredTableSolutions | async" class="side-nav-name-div">
                <span (click)="selectTable(table.id)" (click)="selectTableSpan(table.name)" [ngClass]="{'button-click':spanStatus == table.name && tablesHighlight == true}">{{table.name}}</span>
            </div>
        </div>
    </div>

    <!-- DATA VALIDATION -->
    <div class="side-nav-sub-container">
        <button (click)="expandSubMenu('rundata')" (click)="loadDataValidationSolutions()" class="primary-button" [ngClass]="{'button-click':buttonStatus == 'rundata'}">{{strings["dataValidation"]}}</button>
        <div *ngIf="this.subMenu == 'rundata'" class="side-nav-click-container">
            <input type="text" (keyup)="filterDataValidationSolutionsList($event.target.value)" placeholder="{{strings['search']}}" />
            <div *ngFor="let solutionName of filteredDataValidationSolutions | async" class="side-nav-name-div">
                <span (click)="selectDataValidationSolution(solutionName)" [ngClass]="{'button-click':spanStatus == solutionName && dataValidationsHighlight == true}">{{solutionName}}</span>
            </div>
        </div>
    </div>

    <!-- DATA LAKE || OUTPUT -->
    <div class="side-nav-sub-container">
        <button (click)="expandSubMenu('dataLake')" (click)="loadDataLakeSolutions()"  class="primary-button" [ngClass]="{'button-click':buttonStatus == 'dataLake'}">{{strings["output"]}}</button>
        <div *ngIf="this.subMenu == 'dataLake'" class="side-nav-click-container">
            <input type="text" (keyup)="filterOutputSolutionsList($event.target.value)" placeholder="{{strings['search']}}" />
            <div *ngFor="let solutionName of filteredDataLakeSolutions | async" class="side-nav-name-div">
                <span (click)="selectDataLakeSolution(solutionName)" [ngClass]="{'button-click':spanStatus == solutionName && outputsHighlight == true}">{{solutionName}}</span>
            </div>
        </div>
    </div>

    <!-- RUN LOG -->
    <div class="side-nav-sub-container">
        <button (click)="expandSubMenu('runlog')" (click)="loadRunLogSolutions()" class="primary-button" [ngClass]="{'button-click':buttonStatus == 'runlog'}">{{strings["processLogs"]}}</button>
        <div *ngIf="this.subMenu == 'runlog'" class="side-nav-click-container">
            <input type="text" placeholder="{{strings['search']}}" (keyup)="filterRunLogSolutionsList($event.target.value)">
            <div *ngFor="let solutionName of filteredRunLogSolutions | async" class="side-nav-name-div">
                <span (click)="selectRunLogSolution(solutionName)" [ngClass]="{'button-click':spanStatus == solutionName && runLogHighlight == true }">{{solutionName}}</span>
            </div>
        </div>
    </div>

    <!-- ERROR LOG -->
    <div class="side-nav-sub-container">
        <button (click)="expandSubMenu('errors')" (click)="loadErrorLogSolutions()" class="primary-button" [ngClass]="{'button-click':buttonStatus == 'errors'}">{{strings["warningsAndErrors"]}}</button>
        <div *ngIf="this.subMenu == 'errors'" class="side-nav-click-container">
            <input type="text"  (keyup)="filterErrorSolutionsList($event.target.value)" placeholder="{{strings['search']}}" />
            <div *ngFor="let solutionName of filteredErrorLogSolutions | async" class="side-nav-name-div">
                <span (click)="selectErrorLogSolution(solutionName)" [ngClass]="{'button-click':spanStatus == solutionName && errorHighlight == true}">{{solutionName}}</span>
            </div>
        </div>
    </div>

    <!-- Admin -->
    <div class="side-nav-sub-container">
        <!-- <button (click)="setDisplay('debug')" class="primary-button" [ngClass]="debugDisplay ? 'debug-button-click' : ''">Debug</button> -->
        <button  (click)="expandSubMenu('admin')"(click)="selectedAdmin('admin')" class="primary-button" [ngClass]="{'button-click':buttonStatus == 'admin'}">{{strings["admin"]}}</button>
    </div>
</div>