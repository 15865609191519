export class Parser {
   static parseBoolean(b: string) {
        if (b == null || b == "") {
            return false;
        }
        var bLower = b.toLowerCase();
        var trueValues = ["true", "1", "t", "yes", "y"];
        var falseValues = ["false", "0", "f", "no", "n"];
        var trueFilter = trueValues.find(tv => tv === bLower);
        if (trueFilter) {
            return true;
        }
        var falseFilter = falseValues.find(fv => fv === bLower);
        if (falseFilter) {
            return false;
        }
        return false;
    }
    static parse(data: string): string {
        return data;
    }
}