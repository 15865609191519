// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urls: {
    dashboardServiceUrl: "https://localhost:7802",
    selfUrl: "https://localhost:7602"
  },
  envName: 'local'
};
