
import { Component, ViewChild } from '@angular/core';
import { DisplayService } from 'src/app/services/display.service';
import { TableService } from 'src/app/services/table.service';
import { IntegrationDetails } from 'src/app/models/integration-details';
import { IntegrationAction } from 'src/app/models/integration-action';
import { DurableFunctionService } from 'src/app/services/durable-function.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantService } from 'src/app/services/tenant.service';
import { IntegrationActionParameter } from 'src/app/models/integration-action-parameter';
import { DataLakeService } from 'src/app/services/data-lake.service';
import { AdminService } from 'src/app/services/admin.service';
import { UserV2 } from 'src/app/models/admin/user-v2';
import { Role } from 'src/app/models/admin/role';
import { AuthService } from 'src/app/services/auth-service.service';
import { MyAccess } from 'src/app/models/my-access';
import { StringLocalizerService } from 'src/app/services/string-localizer.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent {

  public user: UserV2;
  public userRoles: Role[] = [];
  private $connections = new BehaviorSubject<IntegrationDetails[]>([]);
  public connections = this.$connections.asObservable();
  public selectedIntegration: string;
  navMode: boolean = true;
  public selectedIndex: number;
  @ViewChild('tabs', { static: false }) tabs;
  childDisplay: string = "";
  childDisplayTable: string = "";
  myAccess: MyAccess = null;

  private $integrations = new BehaviorSubject<IntegrationDetails[]>([]);
  public $integrationActions = new BehaviorSubject<IntegrationAction[]>(null);

  public integrationsList = [];
  public connectionsList = [];
  public tablesList = [];

  public integrationSelected: string = null;
  public processRunning: boolean = false;
  public processFinished: boolean = false;

  public actionDisabled: boolean = false;

  public isDemo: boolean = false;
  public isDemoRunning: boolean = false;
  public isDemoCompleted: boolean = false;
  public isDrawerOpen: boolean = false;
  
  actionDisplay: boolean = false;
  tablesDisplay: boolean = false;
  dataValidationDisplay: boolean = false;
  ErrorsDisplay: boolean = false;
  outputsDisplay: boolean = false;
  processLogDisplay: boolean = false;
  adminDisplay: boolean = false;
  
  buttonStatus: string = "";
  spanStatus: string = "";
  spanButtonStatus: string = "";
  actionsHighlight: boolean = false;
  tablesHighlight: boolean = false;
  dataValidationsHighlight: boolean = false;
  outputsHighlight: boolean = false;
  runLogHighlight: boolean = false;
  errorHighlight: boolean = false;

  public strings: {} = {};

  //Refactor
  public subMenu: string = "";

  private $actionSolutions = new BehaviorSubject<string[]>([]);
  public actionSolutions = this.$actionSolutions.asObservable();
  private $filteredActionSolutions = new BehaviorSubject<string[]>([]);
  public filteredActionSolutions = this.$filteredActionSolutions.asObservable();

  private $tables = new BehaviorSubject<any[]>([]);
  public tables = this.$tables.asObservable();
  private $filteredTableSolutions = new BehaviorSubject<any[]>([]);
  public filteredTableSolutions = this.$filteredTableSolutions.asObservable();

  private $dataValidationSolutions = new BehaviorSubject<string[]>([]);
  public dataValidationSolutions = this.$dataValidationSolutions.asObservable();
  private $filteredDataValidationSolutions = new BehaviorSubject<string[]>([]);
  public filteredDataValidationSolutions = this.$filteredDataValidationSolutions.asObservable();

  private $dataLakeSolutions = new BehaviorSubject<string[]>([]);
  public dataLakeSolutions = this.$dataLakeSolutions.asObservable();
  private $filteredDataLakeSolutions = new BehaviorSubject<string[]>([]);
  public filteredDataLakeSolutions = this.$filteredDataLakeSolutions.asObservable();

  private $runLogSolutions = new BehaviorSubject<string[]>([]);
  public runLogSolutions = this.$runLogSolutions.asObservable();
  private $filteredRunLogSolutions = new BehaviorSubject<string[]>([]);
  public filteredRunLogSolutions = this.$filteredRunLogSolutions.asObservable();

  private $errorLogSolutions = new BehaviorSubject<string[]>([]);
  public errorLogSolutions = this.$errorLogSolutions.asObservable();
  private $filteredErrorLogSolutions = new BehaviorSubject<string[]>([]);
  public filteredErrorLogSolutions = this.$filteredErrorLogSolutions.asObservable();
  intialButtonClick = true;

  get integrations() {
    return this.$integrations.asObservable();;

  }
  get integrationActions() {
    return this.$integrationActions.asObservable();;
  }

  constructor(
    private tenantService: TenantService,
    private displayService: DisplayService,
    private tableService: TableService,
    private durableFunctionService: DurableFunctionService,
    private dataLakeService: DataLakeService,
    private adminService: AdminService,
    private authService: AuthService,
    private stringLocalizerService: StringLocalizerService) 
  {
    this.adminService.$myAccess.subscribe(myAccess => {
      if(myAccess != null){
        this.myAccess = myAccess;
      }
    });
    this.durableFunctionService.$integrations.subscribe(integrations => {
      if (integrations != null) {
        this.$integrations.next(integrations);
      }
    });
    this.stringLocalizerService.strings.subscribe(s => {
      if(s){
        this.strings = this.stringLocalizerService.getSection("sideNav");
      }
    });
  }


  //Used to track which integration that the user currently has selected
  public setIntegration(integrationName: string) {
    this.spanStatus = integrationName;
    this.displayService.setSelectedSpanText(integrationName);
    this.displayService.setIntegration(integrationName);
    this.displayService.selectDataValidationSolution(integrationName);
  }

  //call durable function service and trigger a POST to client integration
  public async runIntegrationAction(actionName: string, params: IntegrationActionParameter[]) {
    this.durableFunctionService.runIntegration(this.integrationSelected, actionName, params);
    this.actionDisabled = true;
    //Keep "Run" button disabled for 5 seconds after activating
    await this.delay(5000);
    this.actionDisabled = false;
  }

  delay(delay: number) {
    return new Promise(r => {
      setTimeout(r, delay);
    });
  }

  async loadMyAccess(){
    await this.adminService.loadMyAccess();
  }

  async getUserAndRoles(){
    if(this.user == null){
      var userId = this.authService.$userClaims.value["df.userid"];
    }
    if(userId != null && this.userRoles.length == 0){
      for await (var userRole of this.user.roles){
        var r = await this.adminService.getRole(userRole.id);
        this.userRoles.push(r);
      }
    }  
  }

  filterActionolutionsList(searchString) {
    if (searchString != "") {
      let newSolutions = this.$actionSolutions.pipe(
        map(solutions => solutions.filter(solution => solution.toLowerCase().includes(searchString.toLowerCase())))
      )
      newSolutions.forEach(x => this.$filteredActionSolutions.next(x))
    } else {
      this.$actionSolutions.forEach(x => this.$filteredActionSolutions.next(x))
    }
  }

  filterTablesSolutionsList(searchString) {
    if (searchString != "") {
      let newSolutions = this.$tables.pipe(
        map(solutions => solutions.filter(solution => solution.name.toLowerCase().includes(searchString.toLowerCase())))
      )
      newSolutions.forEach(x => this.$filteredTableSolutions.next(x))
    } else {
      this.$tables.forEach(x => this.$filteredTableSolutions.next(x))
    }
  }

  filterDataValidationSolutionsList(searchString) {
    if (searchString != "") {
      let newSolutions = this.$dataValidationSolutions.pipe(
        map(solutions => solutions.filter(solution => solution.toLowerCase().includes(searchString.toLowerCase())))
      )
      newSolutions.forEach(x => this.$filteredDataValidationSolutions.next(x))
    } else {
      this.$dataValidationSolutions.forEach(x => this.$filteredDataValidationSolutions.next(x))
    }
  }
  
  filterOutputSolutionsList(searchString) {
    if (searchString != "") {
      let newSolutions = this.$dataLakeSolutions.pipe(
        map(solutions => solutions.filter(solution => solution.toLowerCase().includes(searchString.toLowerCase())))
      )
      newSolutions.forEach(x => this.$filteredDataLakeSolutions.next(x))
    } else {
      this.$dataLakeSolutions.forEach(x => this.$filteredDataLakeSolutions.next(x))
    }
  }

  filterRunLogSolutionsList(searchString) {
    if (searchString != "") {
      let newSolutions = this.$runLogSolutions.pipe(
        map(solutions => solutions.filter(solution => solution.toLowerCase().includes(searchString.toLowerCase())))
      )
      newSolutions.forEach(x => this.$filteredRunLogSolutions.next(x))
    } else {
      this.$runLogSolutions.forEach(x => this.$filteredRunLogSolutions.next(x))
    }
  }

  filterErrorSolutionsList(searchString) {
    if (searchString != "") {
      let newSolutions = this.$errorLogSolutions.pipe(
        map(solutions => solutions.filter(solution => solution.toLowerCase().includes(searchString.toLowerCase())))
      )
      newSolutions.forEach(x => this.$filteredErrorLogSolutions.next(x))
    } else {
      this.$errorLogSolutions.forEach(x => this.$filteredErrorLogSolutions.next(x))
    }
  }

  public expandSubMenu(subMenuName: string) {
    if (this.subMenu == subMenuName) {
      this.subMenu = "";
    } else {
      this.subMenu = subMenuName;
      this.buttonStatus = this.subMenu;
      if (this.intialButtonClick === true) {
        this.setBreadCrumbs(subMenuName);
      }
    }
  }


  //Action methods
  async loadActionSolutions() {
    this.displayService.$selectedGrayOut.next(true);
    this.actionsHighlight = false;

    const solutions = this.tenantService.tenant.integrations.map(i => i.name);
    var reducedActions = await this.reduceActionsByRoles(solutions);
    this.$actionSolutions.next(reducedActions);
    this.$filteredActionSolutions.next(reducedActions);
    this.displayService.$selectedGrayOut.next(false);
  }

  async selectActionSolution(solutionName: string) {
    this.displayService.$selectedGrayOut.next(true);
    this.intialButtonClick = false;
    this.displayService.selectActionSolution(solutionName);
    this.displayService.setDataStudioDisplay("action");
    this.spanStatus = solutionName;
    this.actionsHighlight = true;
    this.displayService.$selectedGrayOut.next(false);
  }

  private async reduceActionsByRoles(actions: string[]){
    await this.loadMyAccess();
    var myAccess = this.adminService.myAccess;
    var reducedList: string[] = [];
    actions.forEach(a => {
      var integrationPermissions = myAccess.integrations.find(i => i.name === a);
      if(integrationPermissions != null && integrationPermissions.process){
        reducedList.push(a);
      }
    });
    return reducedList;
  }

  //Tables methods
  async loadTableNames() {
    this.displayService.$selectedGrayOut.next(true);
    this.tablesHighlight = false;
    await this.tableService.loadTables();
    this.$tables.next(this.tableService.$tables.value);
    this.$filteredTableSolutions.next(this.tableService.$tables.value);
    this.displayService.$selectedGrayOut.next(false);
  }

  async selectTable(tableId: string) {
    this.displayService.$selectedGrayOut.next(true);
    this.intialButtonClick = false;
    this.tableService.selectTable(tableId);
    await this.tableService.loadSelectedTable();
    this.displayService.selectTable(tableId);
    this.displayService.setDataStudioDisplay("tables");
    this.tablesHighlight = true;
    this.displayService.$selectedGrayOut.next(false);
  }

  async selectTableSpan(tableName: string) {
    this.spanStatus = tableName;
  }

  //Data Validation methods
  async loadDataValidationSolutions() {
    await this.dataLakeService.loadDataLakeRunDataSolutionNames();
    var solutions = this.dataLakeService.$dataLakeRunData.getValue().solutionListings.map(s => s.name);
    this.displayService.$selectedGrayOut.next(true);
    this.dataValidationsHighlight = false;
    var reducedSolutions = await this.reduceDataValidationByRoles(solutions);
    this.$dataValidationSolutions.next(reducedSolutions);
    this.$filteredDataValidationSolutions.next(reducedSolutions);
    this.displayService.$selectedGrayOut.next(false);
  }

  private async reduceDataValidationByRoles(actions: string[]){
    await this.loadMyAccess();
    var myAccess = this.adminService.myAccess;
    var reducedList: string[] = [];
    actions.forEach(a => {
      var integrationPermissions = myAccess.integrations.find(i => i.name === a);
      if(integrationPermissions != null && integrationPermissions.dataValidation){
        reducedList.push(a);
      }
    });
    return reducedList;
  }

  
  async selectDataValidationSolution(solutionName: string) {
    this.displayService.$selectedGrayOut.next(true);
    this.intialButtonClick = false;
    this.displayService.selectDataValidationSolution(solutionName);
    await this.dataLakeService.loadDataLakeRunDataDates(solutionName);
    this.displayService.setDataStudioDisplay("rundata");
    this.spanStatus = solutionName;
    this.displayService.dataValidationDrawerOpen(true);
    this.dataValidationsHighlight = true;
    this.displayService.$selectedGrayOut.next(false);
  }

  //Data Lake methods
  async loadDataLakeSolutions() {
    this.displayService.$selectedGrayOut.next(true);
    this.outputsHighlight = false;
    await this.dataLakeService.loadOutputSolutionNames();
    var solutions = this.dataLakeService.$dataLakeFiles.value.solutionListings.map(s => s.name);
    var reducedSolutions = await this.reduceDataLakeSolutionsByRoles(solutions);
    this.$dataLakeSolutions.next(reducedSolutions);
    this.$filteredDataLakeSolutions.next(reducedSolutions);
    this.displayService.$selectedGrayOut.next(false);
  }

  private async reduceDataLakeSolutionsByRoles(actions: string[]){
    await this.loadMyAccess();
    var myAccess = this.adminService.myAccess;
    var reducedList: string[] = [];
    actions.forEach(a => {
      var integrationPermissions = myAccess.integrations.find(i => i.name === a);
      if(integrationPermissions != null && integrationPermissions.dataFiles){
        reducedList.push(a);
      }
    });
    return reducedList;
  }

  async selectDataLakeSolution(solutionName: string) {
    this.displayService.$selectedGrayOut.next(true);
    this.intialButtonClick = false;
    this.displayService.selectDataLakeSolution(solutionName);
    await this.dataLakeService.loadOutputDates(solutionName);
    this.displayService.setDataStudioDisplay("dataLake")
    this.spanStatus = solutionName;
    this.outputsHighlight = true;
    this.displayService.$selectedGrayOut.next(false);
  }

  //Run Log methods
  async loadRunLogSolutions() {
    this.displayService.$selectedGrayOut.next(true);
    this.runLogHighlight = false;
    await this.dataLakeService.loadDataLakeRunDataSolutionNames();
    var solutions = this.dataLakeService.$dataLakeRunData.value.solutionListings.map(s => s.name);
    var reducedSolutions = await this.reduceRunLogByRoles(solutions);
    this.$runLogSolutions.next(reducedSolutions);
    this.$filteredRunLogSolutions.next(reducedSolutions);
    this.displayService.$selectedGrayOut.next(false);
  }

  private async reduceRunLogByRoles(actions: string[]){
    await this.loadMyAccess();
    var myAccess = this.adminService.myAccess;
    var reducedList: string[] = [];
    actions.forEach(a => {
      var integrationPermissions = myAccess.integrations.find(i => i.name === a);
      if(integrationPermissions != null && integrationPermissions.runLog){
        reducedList.push(a);
      }
    });
    return reducedList;
  }

  async selectRunLogSolution(solutionName: string) {
    this.displayService.$selectedGrayOut.next(true);
    this.intialButtonClick = false;
    this.displayService.selectRunLogSolution(solutionName);
    await this.dataLakeService.loadDataLakeRunDataDates(solutionName);
    this.displayService.setDataStudioDisplay("runlog");
    this.spanStatus = solutionName;
    this.runLogHighlight = true;
    this.displayService.$selectedGrayOut.next(false);
  }

  //Error Log methods
  async loadErrorLogSolutions() {
    this.displayService.$selectedGrayOut.next(true);
    this.errorHighlight = false;
    await this.dataLakeService.loadDataLakeRunDataSolutionNames();
    var solutions = this.dataLakeService.$dataLakeRunData.value.solutionListings.map(s => s.name);
    var reducedSolutions = await this.reduceErrorLogByRoles(solutions);
    this.$errorLogSolutions.next(reducedSolutions);
    this.$filteredErrorLogSolutions.next(reducedSolutions);
    this.displayService.errorLogDrawer(true);
    this.displayService.$selectedGrayOut.next(false);
  }

  private async reduceErrorLogByRoles(actions: string[]){
    await this.loadMyAccess();
    var myAccess = this.adminService.myAccess;
    var reducedList: string[] = [];
    actions.forEach(a => {
      var integrationPermissions = myAccess.integrations.find(i => i.name === a);
      if(integrationPermissions != null && integrationPermissions.errorLog){
        reducedList.push(a);
      }
    });
    return reducedList;
  }


  async selectErrorLogSolution(solutionName: string) {
    this.displayService.$selectedGrayOut.next(true);
    this.intialButtonClick = false;
    this.displayService.selectErrorLogSolution(solutionName);
    await this.dataLakeService.loadDataLakeRunDataDates(solutionName);
    this.displayService.setDataStudioDisplay("errors");
    this.spanStatus = solutionName;
    this.errorHighlight = true;
    this.displayService.$selectedGrayOut.next(false);
  }

  //Debug
  async selectedDebug(debug: string) {
    await this.displayService.setDataStudioDisplay(debug);
    this.displayService.setPrimaryButtonText('Debug');
    this.displayService.setSelectedSpanText("");
    this.displayService.setDataStudioTitle("");
  }
  //Debug
  async selectedAdmin(admin: string) {
    await this.displayService.setDataStudioDisplay(admin);
    this.displayService.setPrimaryButtonText('Admin');
    this.displayService.setSelectedSpanText("");
    this.displayService.setDataStudioTitle("");
  }

  setBreadCrumbs(name) {
    if (name === 'action') {
      this.displayService.setPrimaryButtonText(this.strings["actions"]);
    } else {
      if (name === 'tables') {
        this.displayService.setPrimaryButtonText(this.strings["tables"]);
      } else {
        if (name === 'rundata') {
          this.displayService.setPrimaryButtonText(this.strings["dataValidation"]);
        } else {
          if (name === 'dataLake') {
            this.displayService.setPrimaryButtonText(this.strings["output"]);
          } else {
            if (name === 'runlog') {
              this.displayService.setPrimaryButtonText(this.strings["processLogs"]);
            } else {
              if (name === 'errors') {
                this.displayService.setPrimaryButtonText(this.strings["warningsAndErrors"]);
              }
            }
          }
        }
      }
    }
  }
}