import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { IntegrationDetails } from 'src/app/models/integration-details';
import { IntegrationAction } from 'src/app/models/integration-action';
import { DurableFunctionService } from 'src/app/services/durable-function.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActionService } from 'src/app/services/action.service';
import { IntegrationActionParameter } from 'src/app/models/integration-action-parameter';
import { DisplayService } from 'src/app/services/display.service';
import { StringLocalizerService } from 'src/app/services/string-localizer.service';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit {
  @ViewChild("chevron", { read: ElementRef }) chevron: ElementRef;

  public $selectedIntegration = new BehaviorSubject<IntegrationDetails | null>(null);
  public $integrationActions = new BehaviorSubject<IntegrationAction[]>([]);
  public integrationSelected: string = null;
  public processRunning: boolean = false;
  public processFinished: boolean = false;
  public actionDisabled: boolean = false;
  public isDrawerOpen: boolean = false;
  public strings: {} = {};

  private subscriptions: Subscription = new Subscription();

  get integrationActions() {
    return this.$integrationActions.asObservable();
  }

  constructor(
    private durableFunctionService: DurableFunctionService,
    private actionService: ActionService,
    private displayService: DisplayService,
    private stringLocalizerService: StringLocalizerService) { 
      this.subscriptions.add(this.stringLocalizerService.strings.subscribe(s => {
        if(s){
          this.strings = this.stringLocalizerService.getSection("actions");
        }
      }));
  }

  ngOnInit(): void {
    this.subscriptions.add(this.displayService.$selectedActionSolution.subscribe(solution => {
      if (solution != null) {
        this.displayService.setSelectedSpanText(solution);
        this.actionService.selectIntegration(solution);
        this.integrationSelected = solution;
        this.loadIntegrationActions(solution);
        this.durableFunctionService.loadIntegrationHistory(solution);
      }
    }));
  }

  private loadIntegrationActions(integrationName: string) {
    const selectedIntegration = this.durableFunctionService.listIntegrations().find(i => i.name === integrationName);
    if (selectedIntegration) {
      selectedIntegration.actions.forEach(a => {
        a.parameters.forEach(p => {
          if (p.dataType == 4) {
            const trueValues = ["true", "t", "TRUE", "True", "T"];
            if (typeof p.defaultValue === 'string') {
              p.defaultValue = trueValues.includes(p.defaultValue) ? true : false;
            }
          }
        })
      });
      this.$integrationActions.next(selectedIntegration.actions);
    }
  }

  public async runIntegrationAction(actionName: string, params: IntegrationActionParameter[]) {
    this.durableFunctionService.runIntegration(this.integrationSelected, actionName, params);
    this.actionDisabled = true;
    await this.delay(5000);
    this.actionDisabled = false;
  }

  async toggleSpinner() {
    this.processFinished = false;
    this.processRunning = true;
    await this.delay(5000);
    this.processRunning = false;
    this.processFinished = true;
  }

  delay(delay: number) {
    return new Promise(r => setTimeout(r, delay));
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onOpenedChange(event) {
    this.isDrawerOpen = !this.isDrawerOpen;
    if (!this.isDrawerOpen) {
      this.durableFunctionService.loadIntegrationHistory(this.integrationSelected);
    }
  }
}