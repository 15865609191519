import { EditedRows } from "./edited-rows";

export class myTableChanges {
    tableId: string;
    editedRows: EditedRows;

    constructor(tableId: string) {
      this.tableId = tableId;
      this.editedRows = new EditedRows();
       
      }
      editRow(rowId: string, rowData: any) {
        this.editedRows.addRow(rowId, rowData);
      }
      removeRow(rowId: string) {
        this.editedRows.removeRow(rowId);
      }
    }