import { RowChanges } from "./row-changes";

export class EditedRows {
  rows: RowChanges; // Map of rowId to rowData

  constructor() {
    this.rows = new RowChanges();
  }

  addRow(rowId: string, rowData: any) {
    this.rows.set(rowId, rowData);
  }

  getRow(rowId: string) {
    return this.rows.get(rowId);
  }
  removeRow(rowId: string) {
    this.rows.delete(rowId);
  }
}