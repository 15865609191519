export class RowChanges {
  originalChange: Map<string, any> = new Map();
  currentChanges: Map<string, any> = new Map();
  previousChanges: Map<string, any> = new Map();

  set(key: string, value: any) {
    // If originalChange doesn't have the key, store the current value as the original
    if (!this.originalChange.has(key)) {
      this.originalChange.set(key, JSON.parse(JSON.stringify(value)));
    }

    // If previousChanges doesn't have the key, store the current value as the previous change
    if (!this.previousChanges.has(key)) {
      if (this.currentChanges.get(key) !== undefined) {
        this.previousChanges.set(key, JSON.parse(JSON.stringify(this.currentChanges.get(key))));
      } else {
        this.previousChanges.set(key, JSON.parse(JSON.stringify(value)));
      }
    }

    // If currentChanges has the key, store the current value as the previous change
    if (this.currentChanges.has(key) && this.currentChanges.get(key) !== undefined) {
      this.previousChanges.set(key, JSON.parse(JSON.stringify(this.currentChanges.get(key))));
    }

    // Then, set the new value in currentChanges
    this.currentChanges.set(key, value);
  }
  get(key: string) {
    return this.currentChanges.get(key);
  }
  delete(key: string) {
    this.originalChange.delete(key);
    this.currentChanges.delete(key);
    this.previousChanges.delete(key);
  }
}