export class FilterStateService {
    private filterState: { [key: string]: any } = {};
    private sorterApplied: boolean = false; // Add this line

    updateFilterState(column: string, value: any) {
        this.filterState[column] = value;
    }

    getFilterState() {
        return this.filterState;
    }

    clearFilterState() {
        this.filterState = {};
    }

    // Method to update sorter filter state
    updateSorterState(isApplied: boolean) {
        this.sorterApplied = isApplied;
    }

    // Method to check if a sorter filter is applied
    isSorterApplied() {
        return this.sorterApplied;
    }
}